.picker {
    grid-column: 5/10;
    grid-row: 2/4;
    background: cornflowerblue;  
    padding-bottom: 50px;  
  
    input {
      font-size: 80px;
      font-family: "Roboto Condensed";
      font-weight: bold;
  
      color: grey;
      text-align: center;
      border: none;
      background-color: transparent;
      border-bottom: 2px solid #E3B924;
      &:focus {
        outline: none;
      }
    }
  }

  .myCustomDate {
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 450px;
        height: 500px;
        margin-right: 13px;
        margin-left: 3px;
        background-color: blue;
    }
  }

  .testeCSS {
    width: 100%;
    padding: 5px 10px;
    height: 37px;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background-color: blue;
   

    /* &:focus {
        border: 2px solid #E3B924;
    } */

  }