.menudivList {
  padding-bottom: 15px;
  /* position: relative;  
  padding-right: 24px;
  height: 80%; */
}

.pagination {
    display: flex;
    justify-content: end;
    list-style: none;
    cursor: pointer;

/* **************** */
    /* position: absolute;
    margin-bottom: 50px;
    padding-right: 40px;
    bottom: 0;
    right: 0;
    height: 20%; */
  }  
  
  .pagination a {
    /* circle styles */
    width: 40px;
    height: 40px;
    /* border: 0.5px solid #222; */
    border-radius: 10%;
    margin: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: none;
  }  
  
  .pagination__link {
    font-weight: bold;
    color: #1d9bf0;
    background-color: none !important;
    text-decoration: none;
    padding: 0px !important;
    border-radius: 10% !important;
    border: 0px #ffffff !important;
    width: 40px !important;
  }

  .previusAndNextDisableCursor {
   cursor: default; 
  }
  
  .pagination__link:hover {
    background-color: #ffffff;
    color: #3e424e;
  }
  
  .pagination__link--active a {
    color: #ffffff !important;
    background: #1d9bf0;
  }
  
  .pagination__link--disabled a {
    color: #1d9bf0;
    background-color: none !important;
    padding: 0px !important;
    border-radius: 10% !important;
    width: 40px !important;
  }
  
  .divList {
    padding-top: 1%;
  }
  